<template>
  <div class="primary-content">
    <left-column current="get-data"></left-column>
    <div class="col-md-9 right-column">
      <page-header header="Get Data" v-bind:allow_next="can_submit" major_content="Submit" @next-clicked="submit_er" next=""></page-header>
      <div class="form-group">
        <label for="extract-name">Data extract name</label>
        <input class="form-control" type="textbox" id="extract-name" v-model="extract_name" />
        <label for="extract-description">Description (Optional)</label>
        <textarea class="form-control" rows="3" cols="100" id="extract-description" v-model.lazy="description" ></textarea>
      </div>
      <div class="selected-data">
        <div class="selected-data-header">Geographic level</div>
        <div class="selected-data-content">Census Tract (2015-2019 American Community Survey)</div>
        <div class="selected-data-header">Contextual variables <!--<button class="edit-button btn btn-sm">Edit</button>--></div>
        <div class="selected-data-content">
          <ul>
            <li v-for="variable in selected_variables">{{variable.label}}</li>
          </ul>
        </div>
        <div class="selected-data-header">Output format</div>
        <div class="selected-data-content">CSV</div>
      </div>
    </div>
  </div>
</template>

<script>
  import PageHeader from './page-header'
  import LeftColumn from './left-column'
  import axios from 'axios'
  export default {
    components: { PageHeader , LeftColumn },
    computed: {
      can_submit() {
        return this.$store.getters.canSubmit
      },
      selected_variables () {
        return this.$store.state.extractRequest.variables
      },
      extract_name: {
        get () { return this.$store.state.extractRequest.name },
        set (name) {
          this.$store.commit('setName', name)
        }
      },
      description: {
        get () { return this.$store.state.extractRequest.comments },
        set (comments) { this.$store.commit('setComments', comments) }
      }
    },
    methods: {
      submit_er () {
        const csrf_token = document.querySelector("meta[name='csrf-token']").content
        const extract_request = Object.assign({}, this.$store.state.extractRequest)
        const meta_element = document.querySelector("meta[name='user_first_name']")
        const user_first_name = meta_element ? meta_element.content : false
        if(!user_first_name) {
          window.location = "/login?redirect_to=" + this.$route.path
          return
        }
        axios({
          method: "post",
          url: '/extract_requests/submit',
          data: { extract_request },
          headers: { "X-CSRF-TOKEN": csrf_token }
        }).then( (response) => {
          this.$store.commit("clearAll")
          this.$router.push("/history")
        }).catch((error) => {
          alert("An error has occured. Please contact support at ipums@umn.edu.")
        })
      }
    }
  }

</script>
