<template>
  <div>
    <div class="container modify-padding">
      <layout-header></layout-header>
    </div>
    <div class="custom-background">
      <div class="container main-content">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import VueRouter from 'vue-router'
import Home from './home'
import UploadCsv from './upload-csv'
import SelectGeogLevels from './select-geog-levels'
import SelectVariables from './select-variables'
import GetData from './get-data'
import History from './history'
import LayoutHeader from './layout-header'
import extractRequest from './extract-request'
const routes = [
  { path: '/', component: Home },
  { path: '/upload-csv', component: UploadCsv },
  { path: '/select-geog-levels', component: SelectGeogLevels },
  { path: '/select-variables', component: SelectVariables, props: {extractRequest: extractRequest} },
  { path: '/get-data', component: GetData },
  { path: '/history', component: History }
]
console.log(extractRequest)
const router = new VueRouter({routes, mode: 'history'});
router.beforeEach((to, from, next) => {
  const meta_element = document.querySelector("meta[name='user_first_name']")
  const user_first_name = meta_element ? meta_element.content : false
  if(to.path !== '/' && !user_first_name) {
      window.location = "/login?redirect_to=" + to.path
      next(false)
  } else {
    next()
  }
})

export default {
  router,
  data: extractRequest,
  components: { LayoutHeader },
}
</script>

<style scoped>
p {
  font-size: 2em;
  text-align: center;
}
</style>
