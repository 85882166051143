<template>
  <div class="col-md-3 left-column">
    <div class="cart">
      <left-nav-link to="/" class="start-over" v-bind:enabled="allow_click(1)" label="Start Over"></left-nav-link>
      <left-nav-link num="1" class="nav-element" v-bind:enabled="allow_click(2)" label="Upload Data File" to="/upload-csv"></left-nav-link>
      <left-nav-link num="2" class="nav-element" v-bind:enabled="allow_click(3)"  label="Select Geographic Levels" to="/select-geog-levels"></left-nav-link>
      <div class="cart-content">
        <ul id="cart-geog-levels-list">
        </ul>
      </div>
      <left-nav-link num="3" class="nav-element" v-bind:enabled="allow_click(4)" label="Select Variables" to="/select-variables"></left-nav-link>
      <div class="cart-content">
        <ul id="cart-variables-list">
        </ul>
      </div>
      <left-nav-link num="4" class="nav-element" v-bind:enabled="allow_click(5)" label="Get Data" to="/get-data"></left-nav-link>
    </div>
  </div>
</template>

<script>
import LeftNavLink from './left-nav-link'
export default {
  props: ['current'],
  data: function() {
    return {
      "start-over": 1,
      "upload-csv": 2,
      "select-geog-levels": 3,
      "select-variables": 4,
      "get-data": 5,
    }
  },
  methods: {
    allow_click: function(num) {
      let current = this.$data[this.current];
      if(num <= current || num == 2) {
        return true
      }
      else if(num == 3 || num == 4) {
        return this.$store.getters.hasCsv && this.$store.getters.colsPicked
      }
      else if(num == 5) {
        return this.$store.getters.hasVariables && this.$store.getters.hasCsv && this.$store.getters.colsPicked
      } else {
        return false;
      }
    }
  },
  components: { LeftNavLink },
}
</script>
