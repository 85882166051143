<template>
  <div class="header-wrapper">
    <header id="header">
      <div id="logo">
        <a href="https://geomarker.ipums.org/" title="IPUMS.org Home Page">
          <img width:="264" height="75" src="/assets/geomarker_logo.png" alt="geomarker log">
        </a>
      </div>
      <div id="login-logout">
        <div v-if="user_first_name">
          <span>Welcome {{ user_first_name }}</span>
          <a class="left-bar" href="/logout"><span id="logout">Logout</span></a>
        </div>
        <div v-else>
          <a :href="login_link()"><span>Login</span></a>
        </div>
      </div>
      <nav id="primaryNav">
        <ul class="navitem">
          <li><a href="http://ipums.org/">ipums.org</a>
          </li>
          <li><a href="https://geomarker.ipums.org/">Home</a>
          </li>
          <!--<li><router-link to="/">home</router-link>-->
          <!--</li>-->
          <li><router-link to="/">get data</router-link>
          </li>
          <li><router-link to="/history">my data</router-link></li>
          <li><a href="https://geomarker.ipums.org/contact-us/technical-assistance">support</a></li>
        </ul>
      </nav>
    </header>
    <div id="banner"></div>
  </div>
</template>

<script>
export default {
  data () {
    const meta_element = document.querySelector("meta[name='user_first_name']")
    const user_first_name = meta_element ? meta_element.content : false
    return {
      user_first_name
    }
  },
  methods: {
    login_link () {
      return '/login?redirect_to=' + this.$route.path
    }
  }
}
</script>
