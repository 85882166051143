import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
import VueRouter from 'vue-router'
Vue.use(VueRouter)
Vue.use(require('@websanova/vue-upload'));
Vue.use(require('vue-moment'));
import router from './router'
import local_store from 'store'

/*
let moment = require("moment");
if ("default" in moment) {
    moment = moment["default"];
}
*/

document.addEventListener('DOMContentLoaded', () => {
  function emptyRequest() {
    return {
      comments: "",
      name: "",
      extract_type: "",
      csv: {
        name:  {
          new: null,
          original: null
        },
        address_column_mappings: {
          id: "",
          street_address: "",
          city: "",
          state: "",
          zip_code: ""
        },
        coordinate_column_mappings: {
          id: "",
          latitude: "",
          longitude: ""
        },
        metadata: {
          contains_phi: "no",
          headers: [],
          size_in_kb: null,
          row_count: null,
          column_count: null
        },
      },
      variables: []
    }
  }
  const extractRequest = local_store.get('extractRequest', emptyRequest())
  const store = new Vuex.Store({
    state: { extractRequest },
    getters: {
      hasCsv (state) {
        return state.extractRequest.csv.name.new !== null
      },
      hasVariables(state) {
        return state.extractRequest.variables.length !== 0
      },
      hasExtractName(state) {
        return state.extractRequest.name !== ""
      },
      canSubmit(state) {
        return state.extractRequest.csv.name.new !== null
               && state.extractRequest.variables.length !== 0
               && state.extractRequest.name !== ""
      },
      colsPicked(state) {
        let er = state.extractRequest
        if(er.extract_type == "coordinates") {
          let mappings =  er.csv.coordinate_column_mappings
          return mappings.id !== "" && mappings.latitude !== "" && mappings.longitude !== ""
        }
        let mappings = er.csv.address_column_mappings
        return mappings.id !== "" && mappings.street_address !== "" && mappings.city !== "" && mappings.state !== "" && mappings.zip_code !== ""
      }
    },
    mutations: {
      clearAll (state) {
        state.extractRequest = emptyRequest()
        local_store.set('extractRequest', state.extractRequest)
      },
      setPhi (state, phi) {
        state.extractRequest.csv.metadata.contains_phi = phi
        local_store.set('extractRequest', state.extractRequest)
      },
      setVariables (state, variables) {
        state.extractRequest.variables = variables
        local_store.set('extractRequest', state.extractRequest)
      },
      setName (state, name) {
        state.extractRequest.name = name
        local_store.set('extractRequest', state.extractRequest)
      },
      setComments (state, comments) {
        state.extractRequest.comments = comments
        local_store.set('extractRequest', state.extractRequest)
      },
      setCsvName (state, name) {
        state.extractRequest.csv.name.original = name.original
        state.extractRequest.csv.name.new = name.new
        local_store.set('extractRequest', state.extractRequest)
      },
      removeCsvName (state) {
        local_store.set('extractRequest', state.extractRequest)
      },
      setCol(state, payload) {
        state.extractRequest.extract_type  == "coordinates"
          ? state.extractRequest.csv.coordinate_column_mappings[payload.col] = payload.val
          : state.extractRequest.csv.address_column_mappings[payload.col] = payload.val
        local_store.set('extractRequest', state.extractRequest)
      },
      setCsvHeaders(state, val) {
        state.extractRequest.csv.metadata.headers = val
        local_store.set('extractRequest', state.extractRequest)
      },
      setExtractType(state, val) {
        state.extractRequest.extract_type = val
        local_store.set('extractRequest', state.extractRequest)
      },
      removeCsvData(state) {
        state.extractRequest.csv = {
          name:  {
            new: null,
            original: null
          },
          address_column_mappings: {
            id: "",
            street_address: "",
            city: "",
            state: "",
            zip_code: ""
          },
          coordinate_column_mappings: {
            id: "",
            latitude: "",
            longitude: ""
          },
          metadata: {
            contains_phi: "no",
            headers: [],
            size_in_kb: null,
            row_count: null,
            column_count: null
          },
        }
        local_store.set('extractRequest', state.extractRequest)
      }
    }
  })
  const dependencies = Object.assign({}, router, {store})
  const app = new Vue(dependencies).$mount('#vue-mount-point')
  console.log(app)
})
