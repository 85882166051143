<template>
  <div class="content-header">
    <h1>{{ header }}</h1>
      <router-link  :to="next">
        <div @click="next_clicked" class="next-button goto next pull-right" v-bind:class="{ invalid: !allow_next }" data-location="">
          <span class="next-major">{{ major_content }}</span>
          <span class="next-minor">{{ minor_content }}</span>
        </div>
      </router-link>
  </div>
</template>

<script>
export default {
  props: ['header', 'major_content', 'minor_content', 'next', 'allow_next'],
  methods: {
    next_clicked (event) {
      this.$emit("next-clicked")
      if (this.next === "") {
        event.preventDefault()
      }
    }
  }
}
</script>
