<template>
  <div class="primary-content">
    <left-column current="select-variables" ></left-column>
    <div class="col-md-9 right-column">
      <page-header header="Select Variables" v-bind:allow_next="allow_next" major_content="next" minor_content="(Get data)" next="get-data"></page-header>
      <div v-if="!loading" v-for="variable in variables" class="checkbox">
        <label :for="variable.gm_id"><input v-model="selected_variables" :value="variable" type="checkbox" :id="variable.gm_id"/>{{ variable.label }}</label>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from './page-header'
import LeftColumn from './left-column'
import axios from 'axios'
export default {
  props: ['extractRequest'],
  data: function () {
    return {
      variables: [],
      loading: true,
    }
  },
  components: { PageHeader , LeftColumn },
  created () {
    axios.get("/extract_requests/variables").then( (response) => {
      this.variables = response.data
      this.loading = false
    }).catch( (error) => {
      alert("An error has occured. Please contact support at ipums@umn.edu.")
    })
  },
  computed: {
    selected_variables: {
      get () { return this.$store.state.extractRequest.variables },
      set (variables) {
        this.$store.commit('setVariables', variables)
      }
    },
    allow_next() {
      return this.$store.getters.hasVariables
    }
  }
}
</script>
