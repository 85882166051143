<template>
<div class="col-md-12 right-column">
  <h1>select the type of data you wish to upload</h1>
  <div class="row">
    <div class="col-md-6">
      <div class="inside-panel">
        <h2>point locations</h2>
        <p>Select Point Locations if you wish to have contextual variables attached to your latitude and longitude (point location) data.</p>
        <div class="more-info-container">
          <div class="more-info" data-toggle="collapse" data-target="#point-file-info" v-bind:class="{collapsed: collapsePoint}" v-on:click="toggleDropdowns('point')">
            How should I format my point location data?
            <span class="glyphicon glyphicon-triangle-right"></span>
            <span class="glyphicon glyphicon-triangle-bottom"></span>
          </div>
        </div>
        <div id="point-file-info" class="collapsable-more-info-container" v-bind:class="{collapse: collapsePoint}">
          <ul class="format-info">
            <li>
              <strong>
                Include three fields:
              </strong>
              <ul>
                <li>A unique identifier that you can use to link back to your original data</li>
                <li>Latitude</li>
                <li>Longitude</li>
              </ul>
            </li>
            <li>
              <strong>Use one-word column headers for ID, latitude, and longitude fields.</strong>
            </li>
            <li>
              <strong>
                Latitude and Longitudes should be in the WGS84 geographic
                coordinate system. Coordinates in a projected coordinate
                system (e.g., Albers, UTM, Web Mercator) are not latitude/
                longitude, and will produce erroneous results. WGS84 is the
                most common coordinate system for latitude/longitude data.
              </strong>
            </li>
          <li><strong>Save data in a comma-separated values (CSV) file.</strong></li>
            <ul>
              <li>A CSV file is a plain text file containing a data table, with each pair of consecutive data values separated by a comma.</li>
              <li>Most data processing software provides an option to save as "CSV"</li>
            </ul>
          </ul>
          <div><strong>Example of how your CSV could be formatted:</strong></div>
          <table class="table table-bordered table-condensed info-table">
            <tr>
              <th>ID</th>
              <th>Latitude</th>
              <th>Longitude</th>
            </tr>
            <tr>
              <td class="left">1</td>
              <td>44.9730</td>
              <td>-93.2436</td>
            </tr>
            <tr>
              <td class="left">2</td>
              <td>44.9929</td>
              <td>-93.1454</td>
            </tr>
          </table>
        </div>
        <div v-on:click="extractType('coordinates')">
          <router-link class="no-underline" to="upload-csv" ><div  class="btn btn-primary btn-success go-btn">GO</div></router-link>
        </div>
        <a href="https://assets.ipums.org/_files/geomarker/sampleinputpoints.csv" class="center-align">Or try GeoMarker using sample point location data</a>
      </div>
    </div>
    <div class="col-md-6">
      <div class="inside-panel">
        <h2>address data</h2>
        <p>Select Address Data if you wish to have contextual variables attached to your US address data.</p>
        <div class="more-info-container">
          <div class="more-info" data-toggle="collapse" data-target="#address-file-info" v-bind:class="{collapsed: collapseAddress}" v-on:click="toggleDropdowns('address')">
            How should I format my address location data?
            <span class="glyphicon glyphicon-triangle-right" ></span>
            <span class="glyphicon glyphicon-triangle-bottom" ></span>
          </div>
        </div>
        <div id="address-file-info" class="collapsable-more-info-container" v-bind:class="{collapse: collapseAddress}">
          <ul class="format-info">
            <li>
              <strong>Include five fields:</strong>
              <ul>
                <li> A unique identifier that you can use to link back to your original data </li>
                <li> Address </li>
                <li> City </li>
                <li> State (two letter state abbreviations are also acceptable)</li>
                <li> ZIP Code</li>
              </ul>
            </li>
            <li><strong>Use one-word column headers for ID, address, city, state, and ZIP.</strong></li>
            <li><strong>These fields must be present in the data file, even if they are not populated.</strong></li>
            <li><strong>GeoMarker only works for U.S. addresses.</strong></li>
            <li><strong>Save data in a comma-separated values (CSV) file.</strong></li>
            <ul>
              <li>A CSV file is a plain text file containing a data table, with each pair of consecutive data values separated by a comma.</li>
              <li>Most data processing software provides an option to save as "CSV"</li>
            </ul>
          </ul>
          <div><strong>Example of how your CSV could be formatted:</strong></div>
          <table class="csv-example">
            <thead>
              <tr>
                <th>ID</th>
                <th>Address</th>
                <th>City</th>
                <th>State</th>
                <th>ZIP</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="left-align">1</td>
                <td>225 19th Ave S</td>
                <td>Minneapolis</td>
                <td>MN</td>
                <td class="left-align">55455</td>
              </tr>
              <tr>
                <td class="left-align">2</td>
                <td>1702 Lexington Ave N</td>
                <td>Roseville</td>
                <td>MN</td>
                <td class="left-align">55113</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-on:click="extractType('address')">
          <router-link  to="/upload-csv" class="no-underline"><div class="btn btn-primary btn-success go-btn">GO</div></router-link>
        </div>
        <a href="https://assets.ipums.org/_files/geomarker/sampleinputaddresses.csv" class="center-align">Or try GeoMarker using sample address data</a>
      </div>
    </div>
  </div>
</div>
</template>
<script>
export default {
  data: function() {
    return {
      collapsePoint: true,
      collapseAddress: true
    }
  },
  methods: {
    extractType(type) {
      this.$store.commit('setExtractType', type)
    },
    toggleDropdowns(type) {
      if(type == 'point') {
        this.collapsePoint = !this.collapsePoint
      }
      if(type == 'address') {
        this.collapseAddress = !this.collapseAddress
      }
    }
  }
}
</script>

