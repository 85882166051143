<template>
  <div class="primary-content">
    <left-column current="select-geog-levels"></left-column>
    <div class="col-md-9 right-column">
      <page-header header="Select Geographic Levels" v-bind:allow_next="allowNext" major_content="next" minor_content="(Variables)" next="select-variables"></page-header>
      <div class="primary-content">
        <div class="checkbox"><label for="tract-acs"><input type="checkbox" id="tract-acs" disabled="true" checked/>Census Tract (2015-2019 American Community Survey)</label></div>
      </div>
    </div>
  </div>
</template>

<script>
  import PageHeader from './page-header'
  import LeftColumn from './left-column'
  export default {
    data: function() {
      return  {
        allowNext: true
      }
    },
    components: { PageHeader , LeftColumn} }
</script>
