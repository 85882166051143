<template>
  <div>
    <div v-if="loading">
      Loading...  <img src="/assets/loading.gif" width="10" height="10"/>
    </div>
    <div v-else class="col-md-12 right-column">
      <h1>My Data</h1>
      <div class="primary-content">
        <table class="table history-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Date/Time</th>
              <th>Name</th>
              <th>Description</th>
              <th>Extract Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="er in extract_requests">
              <td>{{ er.number }}</td>
              <td>{{ er.created_at }}</td>
              <td class="name-column">{{ er.name }}</td>
              <td class="comments-column">{{ er.comments }}</td>
              <td class="absorbing-column">{{er.status }}<img style="margin-left: 10px" v-if="er.status == 'started' ||er.status == 'in_progress' || er.status == 'queued'" src="/assets/loading" height="15" width="15"><img/> </td>
              <td class="download-column"><a v-if="er.status == 'completed' && should_display_download(er)" v-bind:href="er_download_url(er)">Download</a></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios'


export default {
  data () {
    return {
      loading: true,
      success: false,
      extract_requests: [],
      error: null,
      intervalID: null
    }
  },
  created () {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData()
    this.intervalID = setInterval(this.fetchData, 5000)
  },
  beforeDestroy () {
    clearInterval(this.intervalID)
  },
  watch: {
    // call again the method if the route changes
    '$route': 'fetchData'
  },
  computed: {
  },
  methods: {
    er_download_url(er) {
      return "/download/" + er.id
    },
    moment: function (a, b) {
      //debugger
      return this.$moment(a, b);
    },
    should_display_download: function(er) {
      var created_at = this.$moment(er.created_at, 'MMM DD, YYYY - hh:mm A ZZ').toDate()
      var future_date = new Date();
      var today_date  = new Date();
      
      future_date.setTime(created_at.getTime() + 14 * 86400000); // set the time to 14 days in the future from the created_at time.
      
      if(future_date <= today_date) {
        return false;
      }
      else {
        return true;
      }
    },
    fetchData () {
      this.error = this.post = null
      axios({
        method: "get",
        url: "/extract_requests",
        maxRedirects: 0
      }).
        then( (response) => {
          this.loading = false
          this.extract_requests = response.data.extract_requests
        }).
        catch((error) => {
          if (error.response.status == 403) {
            window.location.href = '/login?redirect_to=' + this.$route.path
          } else {
            alert("An error has occured. Please contact support at nhgis@umn.edu.")
          }
        })
    }
  }
}
</script>
