<template>
  <span @click.capture="clicked">
    <router-link  :to="to">
      <div class="cart-element" v-bind:class="classes" ><span v-if="num.length != 0" class="element-number">{{ num }}</span>{{ label }}</div>
    </router-link>
  </span>
</template>

<script>
export default {
  props: {'to': String, 'num': { type: String, default: ""}, 'label': String, 'enabled': Boolean},
  computed: {
    classes: function () {
      return {
        current: this.$route.path == this.to,
        enabled: this.enabled
      }
    }
  },
  methods: {
    clicked: function(e) {
      if(this.enabled) {
        return true
      } else {
        e.preventDefault();
        return false
      }
    }
  }
}
</script>
