<template>
  <div class="primary-content">
    <left-column current="upload-csv"></left-column>
    <div class="col-md-9 right-column">
      <page-header header="Upload Data File" v-bind:allow_next="allow_next" major_content="next" minor_content="(Geographic levels)" next="select-geog-levels"></page-header>
      <form v-show="!csvName" ref="dropzoneForm" action="/csv-upload" class="dropzone upload-content"> </form>
      <div v-if="csvName">
        <p>File uploaded: {{ csvName }}</p>
        <button @click.prevent="removeFile" >Upload new file</button>
      </div>
      <div v-if="headersEmpty && extractType == 'coordinates'"  class="dropdowns">
        <p class="remark"><strong>Which columns contain the required input data?</strong></p>
        <div class="form-group dropdown-control">
          <label>ID:</label>
          <select v-model="selectedCoordinatesIdCol" class="form-control" id="coordinateId">
            <option v-for="column in csvHeaders" v-bind:value="column">{{column}}</option>
          </select>
        </div>
        <div class="form-group dropdown-control">
          <label>Latitude:</label>
          <select v-model="selectedLatitudeCol" class="form-control" id="latitude">
            <option v-for="column in csvHeaders" v-bind:value="column">{{column}}</option>
          </select>
        </div>
        <div class="form-group dropdown-control">
          <label>Longitude:</label>
          <select v-model="selectedLongitudeCol" class="form-control" id="longitude">
            <option v-for="column in csvHeaders" v-bind:value="column">{{column}}</option>
          </select>
        </div>
      </div>
      <div v-if="headersEmpty && extractType == 'address'" class="dropdowns">
        <p class="remark"><strong>Which columns contain the required input data?</strong></p>
        <div class="form-group dropdown-control">
          <label>ID:</label>
          <select v-model="selectedAddressIdCol" class="form-control" id="addressId">
            <option v-for="column in csvHeaders" v-bind:value="column">{{column}}</option>
          </select>
        </div>
        <div class="form-group dropdown-control">
          <label>Street Address:</label>
          <select v-model="selectedAddressCol" class="form-control" id="street_address">
            <option v-for="column in csvHeaders" v-bind:value="column">{{column}}</option>
          </select>
        </div>
        <div class="form-group dropdown-control">
          <label>City:</label>
          <select v-model="selectedCityCol" class="form-control" id="city">
            <option v-for="column in csvHeaders" v-bind:value="column">{{column}}</option>
          </select>
        </div>
        <div class="form-group dropdown-control">
          <label>State:</label>
          <select v-model="selectedStateCol" class="form-control" id="state">
            <option v-for="column in csvHeaders" v-bind:value="column">{{column}}</option>
          </select>
        </div>
        <div class="form-group dropdown-control">
          <label>ZIP Code:</label>
          <select v-model="selectedZipCol" class="form-control" id="zip_code">
            <option v-for="column in csvHeaders" v-bind:value="column">{{column}}</option>
          </select>
        </div>
      </div>
      <div v-if="headersEmpty" class="form-group">
        <span>
          <p><strong>Does this file contain <a target="_blank" href="https://www.hhs.gov/hipaa/for-professionals/privacy/laws-regulations/index.html">Protected Health Information?</a></strong></p>
          <input type="radio" id="Yes" name="phi" value="yes" v-model="phi">
          <label for="Yes">Yes &nbsp;</label>
          <input type="radio" id="No" name="phi" value="no" v-model="phi">
          <label for="No">No</label>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from './page-header'
import LeftColumn from './left-column'
import Dropzone from 'dropzone'
import axios from 'axios'
require('../../../node_modules/dropzone/dist/dropzone.css')
require('../../../node_modules/dropzone/dist/basic.css')
export default {
  components: { PageHeader , LeftColumn  },
  methods: {
    removeFile (event) {
      this.$store.commit("removeCsvData")
      this.dropzone.removeAllFiles()
    },
    createDropzone () {
      const csrf_token = document.querySelector("meta[name='csrf-token']").content
      const $store = this.$store
      const that = this
      this.dropzone = new Dropzone(this.$refs.dropzoneForm, {
        dictDefaultMessage: "drag CSV here, or click to upload",
        headers: { "X-CSRF-TOKEN": csrf_token },
        maxFilesize: 50000,
        init: function() {
          this.on("success", function (file, response) {
            $store.commit("setCsvName", response.name)
            that.getColumns(response.name.new)
          })
        }
      })
    },
    getColumns(csvName) {
      var store = this.$store;
      axios.get("/parse", {params: {filename: csvName}}).then(response => {
        store.commit('setCsvHeaders', response.data.headers)
      }).catch(error => {
        console.log(error);
        alert("An error has occurred. Please contact support at ipums@umn.edu");
      });
    },
    },
    mounted () {
      this.createDropzone()
    },
    computed: {
    allow_next () {
      return this.$store.getters.hasCsv && this.$store.getters.colsPicked
    },
    csvHeaders() {
      return this.$store.state.extractRequest.csv.metadata.headers
    },
    addressColumnMappings() {
      return Object.keys(this.$store.state.extractRequest.csv.address_column_mappings)
    },
    coordinateColumnMappings() {
      return Object.keys(this.$store.state.extractRequest.coordinate_column_mappings)
    },
    csvName () {
      return this.$store.state.extractRequest.csv.name.original
    },
    extractType() {
      return this.$store.state.extractRequest.extract_type
    },
   headersEmpty () {
      return this.$store.state.extractRequest.csv.metadata.headers.length > 0
    },
    selectedCoordinatesIdCol: {
      get () { return this.$store.state.extractRequest.csv.coordinate_column_mappings.id },
      set (val) {
        this.$store.commit('setCol', {col: 'id', val: val})
      }
    },
    phi: {
      get () { return this.$store.state.extractRequest.csv.metadata.contains_phi },
      set (val) {
        this.$store.commit('setPhi', val)
      }
    },
    selectedLatitudeCol: {
      get () {
        return this.$store.state.extractRequest.csv.coordinate_column_mappings.latitude
      },
      set (val) {
        this.$store.commit('setCol', {col: 'latitude', val: val})
      }
    },
    selectedLongitudeCol: {
      get () { return this.$store.state.extractRequest.csv.coordinate_column_mappings.longitude },
      set (val) {
        this.$store.commit('setCol', {col: 'longitude', val: val})
      }
    },
    selectedAddressIdCol: {
      get () { return this.$store.state.extractRequest.csv.address_column_mappings.id },
      set (val) {
        this.$store.commit('setCol', {col: 'id', val: val})
      }
    },
    selectedCityCol: {
      get () { return this.$store.state.extractRequest.csv.address_column_mappings.city },
      set (val) {
        this.$store.commit('setCol', {col: 'city', val: val})
      }
    },
    selectedStateCol: {
      get () { return this.$store.state.extractRequest.csv.address_column_mappings.state },
      set (val) {
        this.$store.commit('setCol', {col: 'state', val: val})
      }
    },
    selectedZipCol: {
      get () { return this.$store.state.extractRequest.csv.address_column_mappings.zip_code },
      set (val) {
        this.$store.commit('setCol', {col: 'zip_code', val: val})
      }
    },
    selectedAddressCol: {
      get () { return this.$store.state.extractRequest.csv.address_column_mappings.street_address },
      set (val) {
        this.$store.commit('setCol', {col: 'street_address', val: val})
      }
    },
  }
}
</script>

